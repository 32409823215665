import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import '../../styles/terms.scss';

const Terms = () => {
  return (
    <>
      <Helmet>
        <title>Salon Basket - Partners - Terms & Privacy</title>
      </Helmet>
      <main className="p-5">
        <Container>
          <Row>
            <Col>
              <h1>Our Privacy Policy</h1>
              <p>
                This Privacy Policy governs the manner in which Varvadhu Studio
                Pvt Ltd (V V Studio Salon) collects, uses, maintains and
                discloses information collected from users (each, a “User&quot;)
                of the https://vvstudio.in website (“Site&quot;). This privacy
                policy applies to the Site and all products and services offered
                by Varvadhu Studio Pvt Ltd (V V Studio Salon)
              </p>
              <h1>PERSONAL IDENTIFICATION INFORMATION</h1>
              <p>
                We may collect personal identification information from Users in
                a variety of ways, including, but not limited to, when Users
                visit our site, register on the site, place an order, fill out a
                form, respond to a survey, subscribe to the newsletter and in
                connection with other activities, services, features or
                resources we make available on our Site. Users may be asked for,
                as appropriate, name, email address, mailing address, phone
                number, Users may, however, visit our Site anonymously. We will
                collect personal identification information from Users only if
                they voluntarily submit such information to us. Users can always
                refuse to supply personally identification information, except
                that it may prevent them from engaging in certain Site related
                activities.
              </p>
              <h1>NON-PERSONAL IDENTIFICATION INFORMATION</h1>
              <p>
                We may collect non-personal identification information about
                Users whenever they interact with our Site. Non-personal
                identification information may include the browser name, the
                type of computer and technical information about Users, means of
                connection to our Site, such as the operating system and the
                Internet service provider utilized and other similar
                information.
              </p>
              <h1>WEB BROWSER COOKIES</h1>
              <p>
                Our Site may use “cookies&quot; to enhance User experience.
                User’s web browser places cookies on their hard drive for
                record-keeping purposes and sometimes to track information about
                them. User may choose to set their web browser to refuse
                cookies, or to alert you when cookies are being sent. If they do
                so, note that some parts of the Site may not function properly.
              </p>
              <h1>HOW WE USE COLLECTED INFORMATION</h1>
              <p>
                Varvadhu Studio Pvt Ltd (V V Studio Salon) collects and uses
                Users personal information for the following purposes:
                <ul>
                  <li>
                    <h3>To improve customer service</h3>
                    Your information helps us to more effectively respond to
                    your customer service requests and support needs.
                  </li>
                  <li>
                    <h3>To personalize user experience</h3>
                    <p>
                      We may use information in the aggregate to understand how
                      our Users as a group use the services and resources
                      provided on our Site.
                    </p>
                  </li>
                  <li>
                    <h3>To improve our Site</h3>
                    <p>
                      We continually strive to improve our website offerings
                      based on the information and feedback we receive from you.
                    </p>
                  </li>
                  <li>
                    <h3>To process transactions</h3>
                    <p>
                      We may use the information Users provide about themselves
                      when placing an order only to provide service to that
                      order. We do not share this information with outside
                      parties except to the extent necessary to provide the
                      service.
                    </p>
                  </li>
                  <li>
                    <h3>
                      To administer a content, promotion, survey or other Site
                      feature
                    </h3>
                    <p>
                      To send Users information they agreed to receive about
                      topics we think will be of interest to them.
                    </p>
                  </li>
                  <li>
                    <h3>To send periodic emails and SMSs</h3>
                    <p>
                      The email address and mobile number Users provide for
                      order processing, will be used to send them information
                      and updates pertaining to their order. It may also be used
                      to respond to their inquiries, and/or other requests or
                      questions. If User decides to opt-in to our mailing and
                      SMS list, they will receive emails or SMSs that may
                      include company news, updates, related product or service
                      information, etc. The SMS that shall be sent to clients
                      who have registered with us and have been active with us.
                      If at any time the User would like to unsubscribe from
                      receiving future emails, we include detailed unsubscribe
                      instructions at the bottom of each email and for
                      unsubscribing from SMSs / emails, the User may contact us
                      via our Site.
                    </p>
                  </li>
                </ul>
              </p>
              <h1>HOW WE PROTECT YOUR INFORMATION</h1>
              <p>
                We adopt appropriate data collection, storage and processing
                practices and security measures to protect against unauthorized
                access, alteration, disclosure or destruction of your personal
                information, username, password, transaction information and
                data stored on our Site.
              </p>
              <h1>CHANGES TO THIS PRIVACY POLICY</h1>
              <p>
                Varvadhu Studio Pvt Ltd (V V Studio Salon) has the discretion to
                update this privacy policy at any time. When we do, revise the
                updated date at the bottom of this page. We encourage Users to
                frequently check this page for any changes to stay informed
                about how we are helping to protect the personal information we
                collect. You acknowledge and agree that it is your
                responsibility to review this privacy policy periodically and
                become aware of modifications.
              </p>

              <h1>YOUR ACCEPTANCE OF THESE TERMS</h1>
              <p>
                By using this Site, you signify your acceptance of this policy
                and terms of service. If you do not agree to this policy, please
                do not use our Site. Your continued use of the Site following
                the posting of changes to this policy will be deemed your
                acceptance of those changes.
              </p>

              <h1>CONTACTING US</h1>
              <p>
                If you have any questions about this Privacy Policy, the
                practices of this site, or your dealings with this site, please
                contact us at: support@salonbasket.com
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default Terms;
