import React from 'react';
import { Col, Row } from 'react-bootstrap';
import svg1 from '../images/1.svg';
import svg2 from '../images/2.svg';
import svg3 from '../images/3.svg';
import svg4 from '../images/4.svg';
import svg5 from '../images/5.svg';
import svg6 from '../images/6.svg';

const GettingStarted = ({ handleShow }) => {
  return (
    <article className="getting-started">
      <Row className=" w-100">
        <Col>
          <h2 className="text-center">Get started</h2>
        </Col>
      </Row>
      <Row className=" w-100">
        <Col lg={4} className="p-5">
          <img src={svg1} alt="svg" className="mb-3" />
          <h3>1. Sign up as an Expert</h3>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu
            elit ac nulla suscipit consectetur.{' '}
          </p> */}
        </Col>
        <Col lg={4} className="p-5">
          <img src={svg2} alt="svg" className="mb-3" />
          <h3>2. Tell us about yourself</h3>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu
            elit ac nulla suscipit consectetur.{' '}
          </p> */}
        </Col>
        <Col lg={4} className="p-5">
          <img src={svg3} alt="svg" className="mb-3" />
          <h3>3. Answer Interview questions</h3>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu
            elit ac nulla suscipit consectetur.{' '}
          </p> */}
        </Col>
      </Row>
      <Row className=" w-100">
        <Col lg={4} className="p-5">
          <img src={svg5} alt="svg" className="mb-3" />
          <h3>4. Undergo necessary training</h3>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu
            elit ac nulla suscipit consectetur.{' '}
          </p> */}
        </Col>
        <Col lg={4} className="p-5">
          <img src={svg4} alt="svg" className="mb-3" />
          <h3>5. Pay joining Fee</h3>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu
            elit ac nulla suscipit consectetur.{' '}
          </p> */}
        </Col>
        <Col lg={4} className="p-5">
          <img src={svg6} alt="svg" className="mb-3" />
          <h3>6. Start performing services</h3>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu
            elit ac nulla suscipit consectetur.{' '}
          </p> */}
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center align-content-center">
          {' '}
          <button
            className="mx-auto btn btn-custom-simple px-5 py-2 "
            onClick={handleShow}
          >
            Become SB Expert
          </button>
        </Col>
      </Row>
    </article>
  );
};

export default GettingStarted;
