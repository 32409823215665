import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Keys from '../../helper';
import { SHA512 } from 'crypto-js';


const Payment = (props) => {
  const location = useLocation()

  useEffect(() => {
    (async () => {
      try {
        const params = new URLSearchParams(location.search);
        const id = params.get('id');
        const res = await axios.get(`${Keys.api_uri}/wallet/create-online-payment/${id}`)
        const temp = res.data
        const txID = temp._id
        const hashVal = SHA512(
          `${Keys.payuKey}|${txID}|${temp.amount}|Wallet recharge|${temp.lastname}|${temp.email}|||||||||||${Keys.payuSalt}`
        );

        let form = document.createElement('form');
        let key = document.createElement('input');
        let txnid = document.createElement('input');
        let amount = document.createElement('input');
        let firstname = document.createElement('input');
        let productinfo = document.createElement('input');
        let email = document.createElement('input');
        let phone = document.createElement('input');
        let surl = document.createElement('input');
        let furl = document.createElement('input');
        let hash = document.createElement('input');


        form.method = 'POST';
        form.action = `${Keys.payu_uri}/_payment`

        key.type = 'hidden';
        key.value = Keys.payuKey
        key.name = 'key';

        txnid.type = 'hidden';
        txnid.value = txID;
        txnid.name = 'txnid';

        amount.type = 'hidden';
        amount.value = temp.amount;
        amount.name = 'amount';

        firstname.type = 'hidden';
        firstname.value = temp.lastname
        firstname.name = 'firstname';

        productinfo.type = 'hidden';
        productinfo.value = 'Wallet recharge';
        productinfo.name = 'productinfo';

        email.type = 'hidden';
        email.value = temp.email;
        email.name = 'email';

        phone.type = 'hidden';
        phone.value = temp.phone;
        phone.name = 'phone';

        surl.type = 'hidden';
        surl.value = `${Keys.api_uri}/wallet/online-payment/order/success?id=${txID}`;
        surl.name = 'surl';

        furl.type = 'hidden';
        furl.value = `${Keys.api_uri}/wallet/online-payment/order/failed?id=${txID}`;
        furl.name = 'furl';

        hash.type = 'hidden';
        hash.value = hashVal;
        hash.name = 'hash';

        form.appendChild(key);
        form.appendChild(txnid);
        form.appendChild(amount);
        form.appendChild(firstname);
        form.appendChild(productinfo);
        form.appendChild(email);
        form.appendChild(phone);
        form.appendChild(surl);
        form.appendChild(furl);
        form.appendChild(hash);

        document.body.appendChild(form);

        form.submit();

      } catch (error) {
        console.log(error)
      }
    })()
  }, [])


  return (
    <>
    </>
  )
};

export default Payment;
