import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import GeneralInfo from './components/GeneralInfo';
import NavbarComponent from './components/Navbar';
import SignUp from './components/SignUp';
import Home from './pages/home';
import Payment from './pages/payment/Payment';
import Status from './pages/payment/Status';
import Terms from './pages/terms';

const Routes = () => {
  const [show, setShow] = useState(false);
  const [showGen, setShowGen] = useState(false);
  const [requestID, setRequestID] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => {
    const url =
      'https://play.google.com/store/apps/details?id=com.salonbasket.salonbasketpartner';
    window.open(url, '_blank').focus();
  };

  // const handleCloseGen = () => setShowGen(false);
  // const handleShowGen = () => setShowGen(true);
  return (
    <>
      <Router>
        <NavbarComponent
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        />
        <Switch>
          <Route exact path="/">
            <Home
              show={show}
              handleClose={handleClose}
              handleShow={handleShow}
            />
          </Route>
          <Route exact path="/termsandprivacy">
            <Terms />
          </Route>
          <Route exact path="/payment">
            <Payment />
          </Route>
          <Route exact path="/payment/status">
            <Status />
          </Route>
        </Switch>
        <Footer />
      </Router>
      {/* <SignUp
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        setRequestID={setRequestID}
        handleShowGen={handleShowGen}
      />
      <GeneralInfo
        showGen={showGen}
        handleCloseGen={handleCloseGen}
        handleShowGen={handleShowGen}
        requestID={requestID}
        setRequestID={setRequestID}
      /> */}
    </>
  );
};

export default Routes;
