import React, { useEffect, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import banner from '../images/SB-Header.png';

const Hero = ({ handleShow }) => {
  const props = useSpring({
    config: {
      ...config.molasses,
      duration: '1000',
    },
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true,
  });
  const [state, setState] = useState(0);
  useEffect(() => {
    let interval = setInterval(() => {
      setState((state + 1) % 3);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [state]);
  const textArray = [
    <animated.h1 style={props}>
      be your{' '}
      <span
        style={{
          color: '#661073',
        }}
      >
        {' '}
        own boss
      </span>
    </animated.h1>,
    <animated.h1 style={props}>
      set your{' '}
      <span
        style={{
          color: '#661073',
        }}
      >
        own rates
      </span>
    </animated.h1>,
    <animated.h1 style={props}>
      Grow{' '}
      <span
        style={{
          color: '#661073',
        }}
      >
        your business
      </span>
    </animated.h1>,
  ];
  return (
    <>
      <header className="hero-section">
        <div
          className="banner text-center"
          style={{
            backgroundImage: `url('${banner}')`,
          }}
        >
          {' '}
          <div className="banner-content my-2 ">
            <div className="banner-text text-uppercase ">
              {textArray[state]}
            </div>
            <div className="  my-2">
              <span className=" d-block info-text-banner ">
                1000+ SB Experts already on board
              </span>
              <span className=" d-block info-text-banner">
                across top metro cities of india
              </span>
              <button
                onClick={handleShow}
                className="btn btn-custom-simple px-5 py-2 mx-2 text-small-btn my-2"
              >
                Become SB Expert
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Hero;
