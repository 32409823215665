/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import img from '../images/Image-1.png';

const WhatIsSalonBasket = ({ handleShow }) => {
  return (
    <article>
      <Row className="w-100 what-is-section my-5 ">
        <Col lg={8} className="img-bg">
          <img src={img} alt="No Image" className="img-fluid" />
        </Col>
        <Col
          lg={4}
          className="px-5 py-2 d-flex justify-content-center align-items-center "
        >
          <div>
            <h3>What is Salon basket?</h3>
            <p>
              Salon Basket connects busy people in need of a home salon session
              with trusted local beauticians and stylists. As an SB expert, you
              can get paid to do what you love, when and where you want — all
              while saving the day for someone in your city.
            </p>
            <button
              className="mx-auto btn btn-custom-simple px-5 py-2 "
              onClick={handleShow}
            >
              Become SB Expert
            </button>
          </div>
        </Col>
      </Row>
    </article>
  );
};

export default WhatIsSalonBasket;
