import React from 'react';
import { Col, Row } from 'react-bootstrap';

const faq = [
  {
    ques: `<h4>Whats required to become a Salon Basket Expert?</h4>`,
    ans: '<p></p>',
  },
  {
    ques: `<h4>Do I need experience to provide services?
    </h4>`,
    ans: '<p></p>',
  },

  {
    ques: `<h4>How do I get jobs?
    </h4>`,
    ans: '<p></p>',
  },
  {
    ques: `<h4>How do I get paid?
    </h4>`,
    ans: '<p></p>',
  },
  {
    ques: `<h4>Where does Salon Basket operate?
    </h4>`,
    ans: '<p></p>',
  },
  {
    ques: `<h4>What beauty categories can I task in on salon basket?</h4>`,
    ans: '<p></p>',
  },
  {
    ques: `<h4>How long does it take for my registration to be processed?
    </h4>`,
    ans: '<p></p>',
  },
];

export const FAQ = () => {
  return (
    <article className="faq">
      <Row className="my-5 px-5">
        <Col className="my-5" lg={12}>
          <h2 className="text-center">Expert FAQ’s</h2>
        </Col>
        {faq.map((item, i) => (
          <Col className="p-3" lg={6} key={i}>
            <div
              className="faq-ques"
              dangerouslySetInnerHTML={{ __html: item.ques }}
            ></div>
          </Col>
        ))}
      </Row>
      <Row className="my-5 p-5">
        <Col className="text-center">
          <h2>Flexible work, at your fingertips</h2>
          <p className="my-1 p-2">
            Become a Professional beautician / Join us as SB Expert.
          </p>
          <a
            className="btn btn-custom my-1"
            href="https://www.vvstudio.in/beauty-academy"
            target="_blank"
          >
            Join our academy
          </a>
        </Col>
      </Row>
    </article>
  );
};
