import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import { FAQ } from '../../components/FAQ';
import GettingStarted from '../../components/GettingStarted';
import Hero from '../../components/Hero';
import Testimonial from '../../components/Testimonial';
import WhatIsSalonBasket from '../../components/WhatIsSalonBasket';

const Home = ({ show, handleClose, handleShow }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Partner Salon Basket</title>
        <link rel="canonical" href="https://partner.salonbasket.com/" />
        <meta
          name="description"
          content="Partners application for salon basket"
        />
      </Helmet>
      <main>
        <Hero handleShow={handleShow} />
        <Container>
          <WhatIsSalonBasket handleShow={handleShow} />
          <GettingStarted handleShow={handleShow} />
          <Testimonial  />
          <FAQ />
        </Container>
      </main>
    </>
  );
};

export default Home;
