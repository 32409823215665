import React from 'react';
import { Col, Row } from 'react-bootstrap';
import img from '../images/Image-2.png';

const Testimonial = ({ handleShow }) => {
  return (
    <article className="testimonial">
      <Row className="my-5 px-5">
        <Col lg={5}>
          <svg
            width="41"
            height="34"
            viewBox="0 0 41 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.776 22.112C0.776 29.168 4.952 33.056 9.704 33.056C14.024 33.056 17.336 29.6 17.336 25.424C17.336 21.104 14.456 18.08 10.424 18.08C9.704 18.08 8.696 18.224 8.552 18.224C8.984 13.616 13.16 7.85599 17.768 4.832L12.44 0.511993C5.816 5.26399 0.776 13.328 0.776 22.112ZM23.816 22.112C23.816 29.168 27.992 33.056 32.744 33.056C37.064 33.056 40.52 29.6 40.52 25.424C40.52 21.104 37.496 18.08 33.464 18.08C32.744 18.08 31.736 18.224 31.592 18.224C32.168 13.616 36.2 7.85599 40.808 4.832L35.48 0.511993C28.856 5.26399 23.816 13.328 23.816 22.112Z"
              fill="#51AF33"
            />
          </svg>
          <p>
            I love Salon Basket! I was able to get out of debt, tackle bills,
            provide for my family, and still have enough room to save for future
            goals. All this by doing that I absolutely love.
          </p>
          <span className="d-inline-block w-100 mb-3">Kavita, Bangalore</span>

         
        </Col>
        <Col lg={7}>
          <img alt="Kavita" className="img-fluid" src={img} />
        </Col>
      </Row>
    </article>
  );
};

export default Testimonial;
