import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
  FaLinkedin,
} from 'react-icons/fa';

const size = 25;

const Footer = () => {
  return (
    <>
      <footer>
        <Container className="text-center">
          <Row>
            <Col className="px-5 links-social">
              <span className="fw-bold fs-5 mx-2">
                Follow us! We're friendly
              </span>
              <a
                href="https://www.facebook.com/salonbasketindia"
                target="_blank"
              >
                <FaFacebookSquare size={size} />
              </a>
              <a href="https://www.instagram.com/salon.basket/" target="_blank">
                <FaInstagramSquare size={size} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCiQao4m1rOKw1pQhmGSE5xA"
                target="_blank"
              >
                <FaYoutubeSquare size={size} />
              </a>
              <a
                href="https://www.linkedin.com/company/salonbasket?challengeId=AQEmIg97-iBCqAAAAXy7TqtZNfWqD6JVDrCaCrzAM5YHY-JYHVvMUWM4oGGy4WkoPxdPAegjIyUQnEsHxMWDUMUeGw0Z2WTRew&submissionId=c08732f6-1481-b116-d221-79688c6c8f10"
                target="_blank"
              >
                <FaLinkedin size={size} />
              </a>
            </Col>
            <Col className="px-5 text-uppercase links-ft ">
              <a href="https://salonbasket.com/about/">About US</a>
              <a href="https://salonbasket.com/blog">Blog</a>
              <a href="https://salonbasket.com/about/">Contact us</a>
              <a href="/termsandprivacy"> Terms & privacy</a>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
