import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Status = () => {
  const location = useLocation()

  const [status, setStatus] = useState(null)
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status');
    setStatus(Number(status))
  }, [])

  if (status === null) {
    return (<></>)
  }

  return (
    <div style={{
      width: '100%',
      height: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }} >
      <div>
        <h2>{status === 1 ? 'Payment Complete' : 'Payment Failed'}</h2>
        <p>You can close this window and continue using app</p>
      </div>
    </div>
  );
};

export default Status;
