import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../images/icon.png';

const NavbarComponent = ({ show, handleClose, handleShow }) => {
  return (
    <>
      <Navbar className="navbar-custom">
        <Container>
          <Navbar.Brand href="/">
            <img alt="logo" src={logo} className="d-inline-block align-top" />{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end text-uppercase"
          >
            <Nav>
              <Nav.Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleShow();
                }}
                className="btn btn-custom text-dark px-5 py-2 text-small-btn"
              >
                Become SB Expert
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponent;
